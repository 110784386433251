.tooltip-container {
    position: relative;
    display: inline-block;
  }
  
.tooltip-box {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 5px;
  padding: 8px;
  background-color: black;
  color: white;
  border-radius: 4px;
  text-align: center;
  z-index: 1;
  font-size: medium;
}